import * as React from 'react'
import { Paragraph } from '../Typography'
import styled from 'styled-components/macro'

const AddressComponent: React.FC = props => {
  return (
    <Address>
      <Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        We are a fully remote team
      </Paragraph>
      <Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        but feel free to write to us
      </Paragraph>
      <Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        at our registered address:
      </Paragraph>
      <br />
      <Paragraph size='xs'>Big Lemon</Paragraph>
      <Paragraph size='xs'>Britannia House</Paragraph>
      <Paragraph size='xs'>Caerphilly Business Park</Paragraph>
      <Paragraph size='xs'>Caerphilly</Paragraph>
      <Paragraph size='xs'>CF83 3GG</Paragraph>
      <br />
      <a href='tel:+442921679025'>
        <Paragraph size='xs' hasHover={true}>
          02921 679025
        </Paragraph>
      </a>
      <br />
      <a href='mailto:hello@biglemon.co.uk'>
        <Paragraph size='xs' hasHover={true}>
          hello@biglemon.co.uk
        </Paragraph>
      </a>
    </Address>
  )
}

const Address = styled.address`
  & a {
    text-decoration: none;
  }
`

export default AddressComponent
